import Modal, {ModalProps} from "./Modal";
import React from "react";
import "./styles/ProjectModal.scss"

export interface ProjectModalProps extends ModalProps {

}

export interface ProjectModalState {
    imageUrl: string,
    writeup: string,
    title: string,
}

class ProjectModal extends React.Component<ProjectModalProps, ProjectModalState> {

    private baseModal: React.RefObject<Modal>;

    constructor(props: ProjectModalProps) {
        super(props);

        this.state = {
            imageUrl: "",
            writeup: "",
            title: "",
        }

        this.baseModal = React.createRef();

    }

    public setOpenState(isOpen: boolean): void {
        if(this.baseModal.current !== null) {
            this.baseModal.current.setOpenState(isOpen);
        }
    }

    public isOpen(): boolean {
        if(this.baseModal.current !== null) {
            return this.baseModal.current.isOpen();
        }
        return false;
    }

    render() {

        // This can all be converted into 1 class that is inherited to give different functionality

        return (

            <Modal ref={this.baseModal} width={this.props.width} height={this.props.height}>
                <div className="project-modal-container">
                    <h1 className="project-modal-title">{this.state.title}</h1>
                    <img src={this.state.imageUrl} alt={this.state.title} className="project-modal-image" />
                    <h3 className="project-modal-writeup">{this.state.writeup}</h3>
                </div>
            </Modal>

        );
    }

}

export default ProjectModal;