import * as THREE from 'three'
import { Canvas, LoaderReturnType, ObjectMap, RootState, useFrame, useLoader } from "@react-three/fiber";

import "./styles/Avatar.scss"
import { forwardRef, Suspense, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import useMousePosition from '../hooks/useMousePosition';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import useWindowDimensions from '../hooks/useWindowDimensions';
import { time } from 'node:console';
import { animated, useSpring } from '@react-spring/three';
import { useGLTF } from '@react-three/drei';
import ShadowPlane from "./ShadowPlane";


interface SkullProps {
    rotation: THREE.Vector3;
}

function easeInOutCubic(x: number): number {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

function easeOutBounce(x: number): number {
    const n1 = 7.5625;
    const d1 = 2.75;
    
    if (x < 1 / d1) {
        return n1 * x * x;
    } else if (x < 2 / d1) {
        return n1 * (x -= 1.5 / d1) * x + 0.75;
    } else if (x < 2.5 / d1) {
        return n1 * (x -= 2.25 / d1) * x + 0.9375;
    } else {
        return n1 * (x -= 2.625 / d1) * x + 0.984375;
    }
}

function easeInBounce(x: number): number {
    return 1 - easeOutBounce(1 - x);
    }

function easeInOutBounce(x: number): number {
    return x < 0.5
      ? (1 - easeOutBounce(1 - 2 * x)) / 2
      : (1 + easeOutBounce(2 * x - 1)) / 2;
}

function easeOutElastic(x: number): number {
    const c4 = (2 * Math.PI) / 3;
    
    return x === 0
      ? 0
      : x === 1
      ? 1
      : Math.pow(2, -2.5 * x) * Math.sin((x * 2.5 - 0.75) * c4) + 1;
}


const LookingSkull = forwardRef(function LookingSkull(ref) {

    const zDepth = 0;
    
    const { height, width } = useWindowDimensions();
    const mousePosition = useMousePosition();

    const objRef = useRef<THREE.Mesh>(null!);
    
    const setSkullRefs = (primitive: THREE.Mesh) => {

        objRef.current = primitive;
        ref = primitive;

    }

    

    const [skullBobTime, setSkullBobTime] = useState(0);
    const [skullBobDirection, setSkullBobDirection] = useState(true);
    const [loadAnimPlayed, setLoadAnim] = useState(false);

    const [loadAnimTime, setLoadAnimTime] = useState(0);

    const [springs, api] = useSpring(
        () => ({
          scale: 1,
          position: [0, 5, zDepth],
          color: '#ff6d6d',
          config: key => {
            switch (key) {
              case 'scale':
                return {
                  mass: 4,
                  friction: 10,
                }
              case 'position':
                return { mass: 2, friction: 100 }
              default:
                return {}
            }
          },
        }),
        []
      )

    const lookAtCursor = () => {

        const mouseX = mousePosition.x/width - 0.75;
        const mouseY = -mousePosition.y/height+ 0.25;
        objRef.current.lookAt(mouseX,mouseY,2);

    }

    const doOnLoadAnimation = (delta: number) => {

        const start: THREE.Vector3 = new THREE.Vector3(0,5,zDepth);
        const end: THREE.Vector3 = new THREE.Vector3(0,-1,zDepth);

        const newPos: THREE.Vector3 = objRef.current.position.clone().lerpVectors(start,end, easeOutElastic(loadAnimTime));
        
        api.start({
            position: [newPos.x, newPos.y, newPos.z]
        });

        lookAtCursor();

        setLoadAnimTime(loadAnimTime + delta * 0.5);
        if(loadAnimTime > 1){
            setLoadAnim(true);
        }

    }

    useFrame(
        (state: RootState, delta: number) => {

            

           if(objRef.current) {

            if(!loadAnimPlayed){
                doOnLoadAnimation(delta);
                return;
            }

            if(skullBobTime > 1.0){
                setSkullBobDirection(false);
            } else if(skullBobTime < 0.0) {
                setSkullBobDirection(true);
            }

            setSkullBobTime(skullBobTime + (skullBobDirection? delta * 0.5: -delta* 0.5));

            const currentPos: THREE.Vector3 = objRef.current.position;
            const up: THREE.Vector3 = new THREE.Vector3(0,0.2,zDepth);
            const down: THREE.Vector3 = new THREE.Vector3(0,-0.2,zDepth);

            const newPos: THREE.Vector3 = currentPos.clone().lerpVectors(down, up, easeInOutCubic(skullBobTime));

            api.start({
                position: [newPos.x, newPos.y, newPos.z]
            });

            lookAtCursor();

           }
        }
    );

    const gltf = useGLTF("models/Avatar.glb")

    useEffect(() => {
        gltf.scene.traverse((child) => {
          if (child.isObject3D) {
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
      }, [gltf.scene]);

    return (
        
        <animated.mesh
            position={springs.position.to((x, y,z) => [x, y, z])}
            castShadow 
        >
            <primitive
            
            ref={setSkullRefs}

            object={gltf.scene}
            
            scale={0.75}
            castShadow 

            />
        </animated.mesh>
        

    )
}
)

 const Avatar = () => {

    return (

        <div className="avatar-container">
            <Canvas className="avatar-canvas" shadows>
                <Suspense fallback={null}>
                    <LookingSkull />
                </Suspense> 

                <ShadowPlane/>


                {
                // Blue top light
                }
                <spotLight
                    castShadow
                    color={"#7FDBFF"}
                    position={[0,50,0]} 
                    intensity={10} 
                    penumbra={1} 
                    angle={Math.PI/48}
                    shadow-mapSize-width={2048}
                    shadow-mapSize-height={2048}
                 />

                {
                // Pink left light
                }
                <directionalLight color={"#F073B5"}  position={[-1,1,-0.5]} intensity={1.75}/>

                {
                // Blue right light
                }
                <directionalLight color={"#7FDBFF"}  position={[1,-1.1,0.5]} intensity={1.75}/>

                {
                // White back rim light 
                }
                <directionalLight color={"#FFFFFF"}  position={[0,1,-1]} intensity={2}/>

                {
                // Blue fill light
                }
                <directionalLight color={"#ABD9FD"}  position={[0.75,-1.1,0.75]} intensity={0.75}/>

                {
                // Pink key light
                }
                <directionalLight color={"#FFD8E5"}  position={[-0.75,1,0.75]} intensity={0.75}/>
                

                {/* White ambient light */}
                <ambientLight intensity={1.75}/>


            </Canvas>
        </div>

    );


}

export { Avatar as Avatar }
