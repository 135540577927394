import "./styles/About.scss"

function calculateAge(birthday: number) {
  const ageDifMs: number = Date.now() - birthday;
  const ageDate: Date = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function About() {

  const age = calculateAge(new Date(2004,0,11).getTime())

    return (
      <section id="About" className="About">
        <div className="picture-container">

          <img className="about-picture" src="images/me.jpg" alt=""/>
          <img className="about-picture" src="images/shadow.jpg" alt=""/>
          <img className="about-picture" src="images/bunbun.jpg" alt=""/>
          <img className="about-picture" src="images/winsten.jpg" alt=""/>


        </div>
        <div className="writeup-container">
          {
          // <span className="writeup-title">About</span>
          // <div className="writeup-separator"/>
          }
          <div className="writeup-content">
            <span className="writeup-content-header">Hello! I'm Devlyn.</span>

            <span className="block">
          G'day! I'm a {age}-year-old games programmer, software engineer, and musician with a strong focus on systems and tools development, though my expertise extends beyond that. My toolkit includes:

          </span>
            <span className="block">
              <span style={{color: "#00c4ff"}}>C#</span>, <span style={{color: "#00c4ff"}}>C++</span>, <span
                style={{color: "#ffc400"}}>Javascript</span>,&nbsp;
              <span style={{color: "#0066ff"}}>TypeScript</span>, <span style={{color: "#f65454"}}>Java</span>, <span
                style={{color: "#549af6"}}>React</span>,&nbsp;
              <span style={{color: "#ff8824"}}>HTML5</span>, <span style={{color: "#54a0f6"}}>CSS3</span>, <span
                style={{color: "#f6f354"}}>Python</span>,&nbsp;
              <span style={{color: "#0003ff"}}>Lua</span>, and more.
            </span>


            <span className="block">
              I've worked on various projects, including a physics engine, an audio synthesis engine, multiple games, and several smaller tools.
            </span>
            <span className="block">
          On the music side, I'm a self-taught musician. I create game soundtracks, standalone tracks, and experimental demos. I've composed music for game jams and other projects, combining my love for both music and game development.
          </span>
            <span className="block">
          Personally, I'm an animal lover with a special fondness for birds—I share my home with Winston, a galah. I also enjoy outdoor activities, even though I don't venture out as often as I'd like.

          </span>

          </div>
        </div>
      </section>
    );
  }

  export default About;