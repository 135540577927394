import * as THREE from "three";

const ShadowPlane = (props: {position?: THREE.Vector3})=> {

    const setGeometry = (mesh: THREE.Mesh) => {
        if (mesh) {
            const geometry = new THREE.PlaneGeometry(32, 32);
            mesh.geometry = geometry;
        }
    }

    const position =  props.position !== undefined? props.position: new THREE.Vector3(0, -2.5, 0);

    return (
        <>
            <mesh
                ref={setGeometry}
                receiveShadow
                position={position}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[20, 1, 20]}
            >
                <shadowMaterial opacity={0.1}/>
            </mesh>
        </>
    )

};

export default ShadowPlane;