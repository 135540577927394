import React, {PropsWithChildren} from "react";
import "./styles/Modal.scss"

export interface ModalProps {

    width: number | string,
    height: number | string

}

export interface ModalState {

    isOpen: boolean;

}

class Modal extends React.Component<PropsWithChildren<ModalProps>, ModalState> {

    constructor(props: PropsWithChildren<ModalProps>) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    public setOpenState(isOpen: boolean) {
        this.setState({isOpen: isOpen});
    }

    public isOpen(): boolean {
        return this.state.isOpen;
    }

    render() {

        const width: number | string =
            this.state.isOpen? this.props.width: "0px";

        const height: number | string =
            this.state.isOpen? this.props.height: "0px";

        return (


            <div className="modal-canvas"
                 style={{
                     opacity: this.state.isOpen ? 1 : 0,
                     backdropFilter: this.state.isOpen ? "blur(16px)": "none",
                     pointerEvents: this.state.isOpen? "all": "none"
                 }}
            >
                <div
                    className="modal-base"
                    style={{
                        width: width,
                        height: height,
                        padding: this.state.isOpen ? "16px" : "0px",
                    }}>
                    <button
                        className="modal-quit-button"
                        onClick={() => {
                            this.setOpenState(false)
                        }}
                        style={{
                            opacity: this.state.isOpen ? 1 : 0,
                            width: this.state.isOpen ? "50px" : "0px",
                            height: this.state.isOpen ? "50px" : "0px",
                        }}/>

                    {this.props.children}

                </div>
            </div>

        );
    }

}

export default Modal;