import { useEffect, useRef, useState } from "react";
import useMousePosition from "../hooks/useMousePosition";
import "./styles/FancyButton.scss"

interface ButtonProps {
    href?: string,
    target?: string,
    rel?: string,
    className?: string,
}

 const FancyButton = (props: React.PropsWithChildren<ButtonProps>) => {

    const className = props.className === null?`FancyButton`:`FancyButton ${props.className}`

    const buttonRef = useRef<HTMLAnchorElement>(null);
    
    const mousePos = useMousePosition();

    const [relativePosX, setRelPosX] = useState(0); 
    const [relativePosY, setRelPosY] = useState(0); 

    useEffect(()=>{
        if(buttonRef.current){
            setRelPosX(mousePos.x - buttonRef.current.getBoundingClientRect().left);
            setRelPosY( mousePos.y - buttonRef.current.getBoundingClientRect().top);
        }
    },[buttonRef,mousePos])
    
    


    return (

        <>
            <a className={className} href={props.href} target={props.target} rel={props.rel} ref={buttonRef}>

                <span className="content">{props.children}</span>
                <span  style={{top: relativePosY, left: relativePosX}} className="fancy-dot"/>
                

            </a>
        </>

    );


}

export default FancyButton;
