import React from "react";
import {useParams} from "react-router";

import projectInfo from "../../res/project-info.json"
import projects from "../Projects";
import ProjectPage from "../ProjectPage";
import PageNotFound from "../PageNotFound";

export default function ProjectPageProvider(){

    const params = useParams();
    const index = params.pid;

    const isValidIndex =
        index !== undefined
        && parseInt(index) < projectInfo.projects.length
        && parseInt(index) >= 0;

    return (

        <>
            {
                isValidIndex
                    ? <ProjectPage index={parseInt(index)}/>
                    : <PageNotFound/>
            }
        </>

    )

}
