import {useEffect, useRef, useState} from "react";
import * as THREE from "three";
import {Text, useGLTF} from "@react-three/drei";
import useWindowDimensions from "../hooks/useWindowDimensions";
import useMousePosition from "../hooks/useMousePosition";
import {animated, useSpring} from "@react-spring/three";
import {RootState, useFrame} from "@react-three/fiber";

export interface CartridgeProps {
    position: THREE.Vector2,
    scale?: number,
    fontSize?: THREE.Vector3,
    fontOffset?: number,
    model: string,
    name: string,
    textColor: string,
    onClick: Function
}

const Cartridge = (props: CartridgeProps) => {

    const zDepth = -1;

    const objRef = useRef<THREE.Mesh>(null!);
    const gltf = useGLTF(props.model)

    const { height, width } = useWindowDimensions();
    const mousePosition = useMousePosition();
    const [isHovered, setHover] = useState(false);

    const [position, setPosition] = useState<THREE.Vector3>(new THREE.Vector3(0,0,0));
    const [rotation, setRotation] = useState<THREE.Vector3>(new THREE.Vector3(0,0,0));

    const scale = props.scale !== undefined ? props.scale : 4;

    const [sizex, sizey, sizez] = props.fontSize !== undefined
        ? [props.fontSize.x, props.fontSize.y, props.fontSize.z]
        : [0.45,0.45,0.45];

    const fontOffset = props.fontOffset !== undefined? props.fontOffset : -2.25;

    const [targetPosition, setTargetPosition] = useState<THREE.Vector3>(
        new THREE.Vector3(
            props.position.x,
            props.position.y,
            zDepth
        )
    );


    const [springs, api] = useSpring(
        () => ({
            scale: 1,
            position: [props.position.x, props.position.y, zDepth],
            rotation: [0, 0, 0],
            color: '#ff6d6d',
            config: key => {
                switch (key) {
                    case 'scale':
                        return {
                            mass: 4,
                            friction: 10,
                        }
                    case 'position':
                        return { mass: 2, friction: 100 }
                    case 'rotation':
                        return { mass: 1, friction: 50 }
                    default:
                        return {}
                }
            },
        }),
        []
    )

    useEffect(() => {
        gltf.scene.traverse((child) => {
            if (child.isObject3D) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });
    }, [gltf.scene]);

    useEffect(() => {
        document.body.style.cursor = isHovered ? 'pointer' : 'auto'
    }, [isHovered])

    const lookAtCursor = (): THREE.Euler => {

        const mouseX = mousePosition.x/width - 0.60;
        const mouseY = -mousePosition.y/height + 0.5;

        const tRot = objRef.current.quaternion.clone();

        objRef.current.lookAt(mouseX + (props.position.x * 0.5),mouseY + (props.position.y * 0.5),1);

        const lRot = objRef.current.rotation.clone();

        objRef.current.quaternion.copy(tRot);

        return lRot;

    }

    const defaultCursor = () => {
        document.body.style.cursor = 'auto'
    }


    const onCartridgeClicked = () => {

        defaultCursor();
        props.onClick();

    }

    useFrame(
        (state: RootState, delta: number) => {



            if(objRef.current) {

                let depth;
                let frameRotation;

                if(isHovered){

                    depth = -0.5;
                    let euler = lookAtCursor();
                    frameRotation = new THREE.Vector3(euler.x, euler.y, euler.z);
                } else {
                    depth = zDepth;
                    frameRotation = new THREE.Vector3(0,0,0)

                }

                api.start({
                    position: [targetPosition.x, targetPosition.y, depth],
                    rotation: [frameRotation.x, frameRotation.y, frameRotation.z],
                });

                let rawPosition = springs.position.get();
                setPosition(new THREE.Vector3(rawPosition[0],rawPosition[1],rawPosition[2]));

                let rawRotation = springs.rotation.get();
                setRotation(new THREE.Vector3(rawRotation[0],rawRotation[1],rawRotation[2]));

            }
        }
    );

    return (
        <animated.mesh
            position={position}
            rotation={[rotation.x, rotation.y, rotation.z]}
            castShadow

            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}
            onClick={()=>  onCartridgeClicked()}
        >
            <primitive

                object={gltf.scene}
                ref={objRef}

                scale={scale}
            />
            <Text
                position={[0,fontOffset,0]}
                scale={[sizex,sizey,sizez]}
                maxWidth={5}
                color={props.textColor}
                textAlign="center"
                anchorX={"center"}
                anchorY={"middle"}
                outlineColor={props.textColor}
                outlineWidth={0.025}
                outlineOpacity={0.5}
                fontWeight={700}
            >
                {props.name}
                <meshStandardMaterial color={"fff"}/>
            </Text>
        </animated.mesh>
    );

}

export { Cartridge as Cartridge }