import "./styles/Contact.scss"
import React from "react";


function Contact() {


    return (
        <section id="Contact" className="Contact">
            <div className="contact-block">
                devlyn <span className="coloured">[@]</span> margoovian <span className="coloured">[.]</span> com
            </div>
            <a className="contact-block" href="https://www.linkedin.com/in/devlyn-hendrie/" target="_blank" rel="noopener noreferrer">
                linkedin.com/in/devlyn-hendrie
            </a>
            <a className="contact-block" href="https://x.com/sifterau" target="_blank" rel="noopener noreferrer">
                x.com/sifterau
            </a>

        </section>
    );
}

export default Contact;