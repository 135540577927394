import React, {useEffect} from "react";
import "./styles/ProjectPage.scss"

import data from "../res/project-info.json"
import {useNavigate} from "react-router";

interface ProjectProps {
    index: number;
}

interface SectionProps {
    title:string,
    content:{subheading:string, text:string, images:{url:string,width:number,height:number}[], videos:{url:string,width:number,height:number}[]}[],
    imageUrls: string[],
    tags: {tag: string, hex: string}[],
    roles: {role: string, hex: string}[],
    key?: number
}

interface TagProps {
    text: string,
    hexColour: string,
    key?: number
}

function Tag(props: TagProps) {
    return (
        <span key={props?.key} className="project-tag" style={{borderColor: props.hexColour, color: props.hexColour}}>{props.text}</span>
    )
}

function Section(props: SectionProps) {
    return (

        <section className="project-section">
            <div className="project-section-title-container">
                <h1 className="project-section-title">{props.title}</h1>
            </div>

            <div className="project-tags">
                {props.tags.map((tag:{tag:string, hex: string}, i) => {
                    return (
                        <Tag key={i} text={tag.tag} hexColour={tag.hex} />
                    )
                })}
            </div>

            <div className="seperator"/>

            <div className="project-section-content">
                {props.content.map((content:{subheading: string, text:string, images:{url:string,width:number,height:number}[], videos:{url:string,width:number,height:number}[]}, i) => {
                    return (
                        <div className="project-section-chunck">
                            {
                                content.subheading !== "" && content.subheading !== null?
                                    <div className="project-subheading">
                                        <h1 className="project-subheading-title">{content.subheading}</h1>
                                        <div className="subheading-seperator"/>
                                    </div>: <div className="project-subheading" style={{display:"none"}}></div>
                            }

                            <span className="project-section-text">
                                {content.text}
                            </span>
                            <div className="section-content-picture-container">
                                {content.images.map((image: {url:string,width:number,height:number}, i) => {
                                    return (<img className="content-picture" src={image.url} alt=""  style={{width:`${image.width}%`, height:`${image.height}%`}} key={i} />);
                                })}
                            </div>
                            <div className="section-content-video-container">
                                {content.videos.map((video: {url:string,width:number,height:number}, i) => {
                                    return (<img className="content-picture" src={video.url} alt=""/>);
                                })}
                            </div>
                        </div>
                    )
                })}


            </div>
        </section>

    );

}

export default function ProjectPage(props: ProjectProps) {

    const project = data.projects[props.index];
    const navigate = useNavigate();

    const ProjectLanguages = () => {


        if(project.languages !== undefined) {
            return (
                <div className="stack">

                    <span className="project-language-label">Languages / Frameworks</span>
                    <div className="project-languages">


                        {project.languages.map((language: { language: string, hex: string }, i) => {
                            return (
                                <Tag text={language.language} hexColour={language.hex}/>
                            )
                        })}


                    </div>
                </div>
            );
        } else return null;

    }

    const ProjectImages = () => {

        return (

            <div className="stack">
                <span className="project-picture-label">Pictures</span>
                <div className="picture-grid">
                    {project.imageUrls.map((imageUrl, i) => {
                        return (
                            <img className="picture-grid-item" src={imageUrl} alt=""/>
                        )
                    })}
                </div>
            </div>
        );


    }

    const ProjectLinks = () => {
        return(
            <div className="stack">
                <span className="project-language-label">Links</span>
                {project.links.map((link:{title:string, url:string, iconUrl:string}, i) => {
                    return (
                        <a className="project-link" href={link.url} data-content={link.iconUrl}>{link.title}</a>
                    )
                })}
            </div>
        );
    }

    const LanguagesProvider = () => {
        if(project.languages.length > 0){
            return <ProjectLanguages/>
        } else return <div style={{display:"none"}}/>
    }

    const ImagesProvider = () => {
        if(project.imageUrls.length > 0){
            return <ProjectImages/>
        } else return <div style={{display:"none"}}/>
    }

    return (

        <main className="project-page">
            <button className="back-button" onClick={() => {
                navigate("/");
            }}>
                <span className="back-button-icon"></span>
            </button>
            <img className="project-banner" src={project.bannerUrl} alt=""/>
            <div className="project-header-wrapper">
            <div className="project-header">
                <div className="project-title-stack">
                    <div>
                        <h1 className="project-title">{project.name}</h1>
                        <div className="project-title-seperator"/>
                    </div>

                    <LanguagesProvider/>

                    <ImagesProvider/>

                </div>
                <div className="writeup-stack">
                    <h1 className="project-writeup-title">Project Writeup</h1>
                    <div className="project-title-seperator"/>
                    <div className="writeup-block">
                        {project.writeup}
                    </div>
                </div>
            </div>
            </div>


            {project.sections.map(
                (section, i) => {
                    return (
                        <Section title={section.title} content={section.content} imageUrls={section.imageUrls}
                                 roles={section.roles} tags={section.tags}/>
                    )
                })}

        </main>

    )

}