import "./styles/Projects.scss"
import React, {useRef} from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import ProjectModal from "../components/ProjectModal";
import CartridgeRack from "../components/CartridgeRack";

function Projects() {

    const modalRef = useRef<ProjectModal>(null);
    const {width, height} = useWindowDimensions();


    const isMobile: boolean = width <= 1200;


    return (
        <section id="Projects" className="Projects">

            <ProjectModal ref={modalRef} width={"75%"} height={"75%"}/>

            <h1 className="projects-title">Projects</h1>

            <CartridgeRack modal={modalRef} />



        </section>
    );
}

export default Projects;