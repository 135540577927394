import React from "react";
import "./styles/PageNotFound.scss"

export default function PageNotFound(){
    return (

        <div className="page-not-found">
            Sorry, we couldn't find any page!
        </div>

    )

}
