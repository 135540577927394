import * as THREE from 'three'
import { Canvas, useFrame } from "@react-three/fiber";

import "./styles/PageDivider.scss"
import Wave from './Wave';

 const PageDivider = () => {

    return (

        <div className='Divider'>

            <Wave id="divider-1"/>
            
            <Wave id="divider-2"/>

        </div>

    );


}

export default PageDivider;
