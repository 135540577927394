import React from 'react';
import './styles/Home.scss';
import { Avatar } from '../components/Avatar';
import Wave from '../components/Wave';
import FancyButton from '../components/FancyButton';

function Home() {


  return (
    <section className="Home">

      <Avatar/>


      <Wave id="1"/>

      
      <div className="home-content">

          <div className='content-box'>
                <div className='title'>
                  <span className='title-letter'>M</span>
                  <span className='title-letter'>a</span>
                  <span className='title-letter'>r</span>
                  <span className='title-letter'>g</span>
                  <span className='title-letter'>o</span>
                  <span className='title-letter'>o</span>
                  <span className='title-letter'>v</span>
                  <span className='title-letter'>i</span>
                  <span className='title-letter'>a</span>
                  <span className='title-letter'>n</span>
                </div>

                <div className="catch-paragraphs">
                  <span className="creator-para">
                      Creative <span className='day'>Dreamer</span>
                  </span>

                  <span className='tinkerer-para'>
                      Dream <span className='heart'>Maker</span>
                  </span>
                </div>

                <div className='socials'>
                  <a href="https://github.com/margoovian" target="_blank" rel="noopener noreferrer" className='github'/>
                  <a href="https://www.linkedin.com/in/devlyn-hendrie/" target="_blank" rel="noopener noreferrer" className='linkedin'/>
                  <a href="https://x.com/sifterau" target="_blank" rel="noopener noreferrer" className='twitter'/>
                  <a href="https://soundcloud.com/sifterau" target="_blank" rel="noopener noreferrer" className='soundcloud'/>
                  <a href="https://margoovian.itch.io/" target="_blank" rel="noopener noreferrer" className='itchio'/>
                </div>

                <div className='quick-nav'>
                    <FancyButton className={"fancy-button"} href="#About">About</FancyButton>
                    <FancyButton className={"fancy-button"} href="#Projects">Projects</FancyButton>
                    <FancyButton className={"fancy-button"} href="#Contact">Contact</FancyButton>
                </div>
          </div>

      </div>
     
    </section>
  );
}

export default Home;
